<template>
  <div class="">
    <el-card shadow="never">
      <div slot="header">
        <h3>
          {{
            type == 1
              ? "商城版块"
              : type == 2
              ? "预约版块"
              : type == 3
              ? "动态文章"
              : type == 4
              ? "产品手册"
              : type == 5
              ? "解决方案"
              : type == 6
              ? "电子图册"
              : type == 7
              ? "商品分类"
              : "搜索管理"
          }}
        </h3>
      </div>
      <div class="flex ju-be">
        <el-upload
          :action="uploadUrl"
          class="upload-demo"
          :on-progress="handProgress"
          :on-success="handleSuccess"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :on-error="handleError"
          :file-list="fileList"
          :show-file-list="false"
          :limit="6"
        >
          <el-button type="primary">上传图片</el-button>
          <!-- <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*422像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M，最少上传1张最多可上传6张
            </div> -->
        </el-upload>
        <el-button type="primary" @click="saveForm('0')">保存</el-button>
      </div>
      <!-- <el-form :model="ruleForm" label-width="auto">
        <el-form-item>
          <el-dialog
            :visible.sync="dialogVisible"
            :modal-append-to-body="false"
            :destroy-on-close="true"
          >
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form> -->
      <commonTable
        :tableData="fileList"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :loading="loading"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column
            type="index"
            align="center"
            width="50"
            label="序号"
          />
          <el-table-column label="图片" align="center">
            <div slot-scope="scope">
              <img class="w-70 h-56 ra-8" :src="scope.row.url" alt="" />
            </div>
          </el-table-column>
          <el-table-column align="center" label="跳转至">
            <div class="flex" slot-scope="scope">
              <el-select v-model="scope.row.content" placeholder="请选择">
                <el-option
                  v-for="item in optionList"
                  :key="item.text"
                  :label="item.text"
                  :value="item.text"
                >
                </el-option>
              </el-select>
              <el-button
                v-if="isShowGoodsBtn(scope.row.content)"
                class="ml-16"
                type="primary"
                @click="changeGoods(scope.row.content, scope.row.index)"
                >选择{{ scope.row.content }}</el-button
              >
            </div>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <div slot-scope="scope">
              <el-button type="text" @click="daleteFile(scope.row.index)"
                >删除</el-button
              >
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <!-- 商品选择 -->
    <goodsList
      :memberData="goodsList"
      :dialogWidth="'60%'"
      :title="goodsTitle"
      :loading="loadings"
      :dialogTableVisible="dialogVisibleGoods"
      :total="totals"
      :selectType="selectType"
      @dialogClose="dialogClose"
      @onConfirmShop="onConfirmShop"
      @dialogCurrentChange="dialogCurrentChange"
    >
    </goodsList>
  </div>
</template>

<script>
import { insertCompanyPhoto, selectCompanyPhoto } from "@/api/client";
import { getGoodsInfoPCShop } from "@/api/miniProgram";
import { fetchAppointmentGoodsList } from "@/api/companyManage.js";
import { selectGoodsTypeInfoPC } from "@/api/coupon.js";
import commonTable from "@/components/common/commonTable";
import goodsList from "../miniProgram/components/goodsList.vue";
export default {
  components: {
    commonTable,
    goodsList,
  },
  data() {
    return {
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: "",
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      type: 1,
      isLoading: false,
      currentPage: 1, //当前页数
      pageNum: 1,
      loading: false,
      total: 0, //总条数
      pageSize: 10,
      tableData: [],
      optionList: [
        {
          text: "无",
        },
        {
          text: "商品分类",
          isPages: false,
          type: "classify",
        },
        {
          text: "普通商品",
          isPages: false,
          type: "isOrdinary",
        },
        {
          text: "秒杀商品",
          isPages: false,
          type: "isKill",
        },
        {
          text: "拼团商品",
          isPages: false,
          type: "isAssemble",
        },
        {
          text: "分销商品",
          isPages: false,
          type: "isDistribution",
        },
        {
          text: "三方平台",
          isPages: false,
          type: "model",
          pagePath: "",
        },
        {
          text: "名片页",
          isPages: true,
          pagePath: "pages/index/main",
        },
        {
          text: "媒体页",
          isPages: true,
          pagePath: "pages/media/main",
        },
        {
          text: "探索页",
          isPages: true,
          pagePath: "pages/explore/main",
        },
        {
          text: "商城页",
          isPages: true,
          pagePath: "pages/Products/main",
        },
        {
          text: "动态页",
          isPages: true,
          pagePath: "pages/Dynamic/main",
        },
        {
          text: "官网页",
          isPages: true,
          pagePath: "pages/WebSite/main",
        },
      ],
      dialogVisibleGoods: false,
      goodsTitle: "",
      currentPages: 1, //当前页数
      pageNums: 1,
      loadings: false,
      totals: 0, //总条数
      pageSizes: 10,
      goodsList: [],
      selectType: "",
      selectGoods: [],
      currentSelect: "",
      curtent: "",
    };
  },
  computed: {
    uploadDisabled() {
      return this.fileList.length >= 6;
    },
  },
  created() {
    this.$set(this, "type", this.$route.query.type);
    this.getCompanyPhoto();
  },

  methods: {
    daleteFile(i) {
      console.log(i, "删除图片");
      this.fileList.splice(i, 1);
      this.$message.success("删除成功！");
      if (this.fileList.length > 0) {
        this.fileList.forEach((el, index) => {
          el.index = index;
        });
      }
    },
    //弹出框关闭
    dialogClose() {
      this.dialogVisibleGoods = false;
      this.goodsList = [];
      this.curtent = "";
    },
    onConfirmShop(goods, type, currentSelect) {
      console.log(goods, type, currentSelect, "goods,type,currentSelect");
      if (goods) {
        if (this.goodsTitle == "商品分类") {
          this.fileList.forEach((el) => {
            if (this.curtent == el.index) {
              el.goodsId = goods.goodstypeId;
            }
          });
        } else if (type == "model") {
          this.fileList.forEach((el) => {
            if (this.curtent == el.index) {
              el.pageUrl = goods.url;
            }
          });
        } else {
          this.fileList.forEach((el) => {
            if (this.curtent == el.index) {
              el.goodsId = goods.goodsId;
            }
          });
        }
        console.log(this.optionList, "99999");
      }
    },
    dialogCurrentChange(val) {
      this.currentPages = val;
    },
    changeGoods(title, i) {
      this.goodsTitle = title;
      this.totals = 0;
      this.currentPages = 1;
      this.curtent = i;
      this.optionList.forEach((el) => {
        if (el.text == title) {
          this.selectType = el.type;
        }
      });
      if (
        this.selectType &&
        this.selectType !== "classify" &&
        this.selectType !== "appointment"
      ) {
        //商品
        this.queryShop();
      } else if (this.selectType == "classify") {
        //商品分类
        this.queryClassify();
      } else if (this.selectType == "appointment") {
        //预约商品
        this.queryAppointment();
      }
      this.dialogVisibleGoods = true;
    },
    queryShop() {
      let data = {
        pageNum: this.currentPages,
      };
      this.loadings = true;
      data[this.selectType] = 1;
      getGoodsInfoPCShop(data)
        .then((res) => {
          if (res.data) {
            this.loadings = false;
            let data = res.data;
            if (data.pageInfo) {
              this.totals = data.pageInfo.total;
              this.goodsList = data.pageInfo.list;
            } else if (data.data.pageInfo) {
              this.total = data.data.pageInfo.total;
              this.goodsList = data.data.pageInfo.list;
            }
          } else {
            this.goodsList = [];
          }
        })
        .catch((err) => {
          this.loadings = false;
        });
    },
    queryAppointment() {
      let than = this;
      let data = {
        pageNum: this.currentPages,
      };
      this.loadings = true;
      fetchAppointmentGoodsList(data)
        .then((res) => {
          if (res.data) {
            than.loadings = false;
            let data = res.data.pageInfo;
            than.totals = data.total;
            than.goodsList = data.list;
            than.goodsList.forEach((v) => {
              v.price = JSON.parse(v.price);
              if (typeof v.price == "object") {
                v.price = v.price[0] + "~" + v.price[1];
              }
            });
          } else {
            than.goodsList = [];
          }
        })
        .catch((err) => {
          this.loadings = false;
        });
    },
    queryClassify() {
      let data = {
        pageNum: this.currentPages,
      };
      this.loadings = true;
      selectGoodsTypeInfoPC(data)
        .then((res) => {
          if (res.data) {
            this.loadings = false;
            let data = res.data.pageInfo;
            this.totals = data.total;
            this.goodsList = data.list;
          } else {
            this.goodsList = [];
          }
        })
        .catch((err) => {
          this.loadings = false;
        });
    },
    // 判断是否展示选择商品按钮
    isShowGoodsBtn(item) {
      let aa = "";
      if (item) {
        if (item == "无") {
          aa = true;
        } else {
          this.optionList.forEach((el) => {
            if (el.text == item) {
              aa = el.isPages;
            }
          });
        }
      } else {
        aa = true;
      }
      return !aa;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    handProgress(event, file, fileList) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(res, file, fileList) {
      this.$message.closeAll();
      this.isLoading = false;
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let timer = this.fileList.length;
      console.log(timer, "shijian");
      this.fileList.push({
        url: `https://cdn-oss.jquen.com/${res.data}`,
        index: timer,
      });
      console.log(this.fileList);
    },
    handleError() {
      this.isLoading = false;
    },
    // 获取页面路径
    getPageUrl(item) {
      let str = "";
      if (item) {
        this.optionList.forEach((el) => {
          if (el.text == item) {
            if (el.isPages) {
              str = el.pagePath;
            }
          }
        });
      }
      return str;
    },
    /**@method 发布 */
    saveForm() {
      // photoUrl
      // content
      // pageUrl
      // goodsId
      // let photos = this.fileList.map((item) =>
      //   item.response.data.indexOf("https") >= 0
      //     ? item.response.data
      //     : this.ossUrl + item.response.data + ".primary.png"
      // );
      let pkq = [];
      this.fileList.forEach((el) => {
        pkq.unshift({
          photoUrl: el.url,
          content: el.content,
          pageUrl:
            el.content == "三方平台" ? el.pageUrl : this.getPageUrl(el.content),
          goodsId: el.goodsId || "",
        });
      });
      let data = {
        type: this.type,
        photoList: pkq,
      };
      console.log(data, "提交的数据");
      insertCompanyPhoto(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "设置成功",
            type: "success",
          });
          this.$router.go(-1);
        }
      });
    },
    getCompanyPhoto() {
      let data = {
        type: this.type,
      };
      selectCompanyPhoto(data)
        .then((res) => {
          if (res.data.length > 0) {
            let arr = res.data;
            arr.forEach((el, index) => {
              this.fileList.push({
                url: el.photo,
                content: el.content,
                pageUrl: el.pageUrl,
                goodsId: el.goodsId,
                index: index,
              });
            });
            console.log(this.fileList, "图片数据");
          }
        })
        .catch((err) => {
          this.fileList = [];
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
  display: none;
}

::v-deep.upload-demo {
  .el-upload--text {
    width: auto;
    height: auto;
    border: none;
    border-radius: 2px;
  }
}
</style>